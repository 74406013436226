// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-actions-request-account-deletion-js": () => import("./../../../src/pages/actions/request-account-deletion.js" /* webpackChunkName: "component---src-pages-actions-request-account-deletion-js" */),
  "component---src-pages-actions-switchteam-js": () => import("./../../../src/pages/actions/switchteam.js" /* webpackChunkName: "component---src-pages-actions-switchteam-js" */),
  "component---src-pages-enterprise-js": () => import("./../../../src/pages/enterprise.js" /* webpackChunkName: "component---src-pages-enterprise-js" */),
  "component---src-pages-features-js": () => import("./../../../src/pages/features.js" /* webpackChunkName: "component---src-pages-features-js" */),
  "component---src-pages-gmail-js": () => import("./../../../src/pages/gmail.js" /* webpackChunkName: "component---src-pages-gmail-js" */),
  "component---src-pages-help-mdx": () => import("./../../../src/pages/help.mdx" /* webpackChunkName: "component---src-pages-help-mdx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legal-email-disclaimer-mdx": () => import("./../../../src/pages/legal/email-disclaimer.mdx" /* webpackChunkName: "component---src-pages-legal-email-disclaimer-mdx" */),
  "component---src-pages-legal-privacy-mdx": () => import("./../../../src/pages/legal/privacy.mdx" /* webpackChunkName: "component---src-pages-legal-privacy-mdx" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-mobile-js": () => import("./../../../src/pages/mobile.js" /* webpackChunkName: "component---src-pages-mobile-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-signup-js": () => import("./../../../src/pages/signup.js" /* webpackChunkName: "component---src-pages-signup-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/Post.js" /* webpackChunkName: "component---src-templates-post-js" */)
}

