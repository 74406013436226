import React from 'react'

import { PricingPlanProvider } from './src/pricingPlanContext'
import CookieConsent from './src/components/CookieConsent'

export const onClientEntry = () => {
	// Catch url search params and store in localStorage for components to use
	if (typeof window !== 'undefined' && typeof localStorage !== 'undefined') {
		const searchParams = new URLSearchParams(window.location.search)
		const data = {}
		searchParams.forEach((value, key) => {
			data[key] = value
		})
		localStorage.setItem('ty-www.searchParams', JSON.stringify(data))
	}
}

export const wrapRootElement = ({ element }) => (
	<>
		<PricingPlanProvider>{element}</PricingPlanProvider>
		<CookieConsent />
	</>
)
