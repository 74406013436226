import React from 'react'
import { Link as GatsbyLink, useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import seedrandom from 'seedrandom'
import startCase from 'lodash/startCase'

import { makeStyles } from '@material-ui/core'
import {
	Card,
	CardActionArea,
	CardContent,
	CardMedia,
	Grid,
	Typography,
} from '@material-ui/core'

const SOFT_COLORS = [
	'#F7A2A1',
	'#EDA9B1',
	'#E3B0C0',
	'#D9B8D0',
	'#CEBFE0',
	'#C4C6EF',
	'#BACDFF',

	'#4AC49E',
	'#4BB7A7',
	'#4CAAB0',
	'#4E9DB9',
	'#4F90C1',
	'#5083CA',
	'#5176D3',

	'#ECE076',
	'#ECD176',
	'#ECC176',
	'#EDB276',
	'#EDA376',
	'#ED9376',
	'#ED8476',

	'#ECAF76',
	'#E7A377',
	'#E39778',
	'#DE8C7A',
	'#D9807B',
	'#D5747C',
	'#D0687D',
]

const splitArticlesIntoSections = articles => {
	const sections = {}
	articles.forEach(article => {
		const { slug } = article.fields
		const pieces = slug.slice(1).split('/')
		if (pieces.length > 2) {
			sections[pieces[1]] = sections[pieces[1]] || {
				id: pieces[1],
				title: startCase(pieces[1]),
				articles: [],
			}
			sections[pieces[1]].articles.push(article)
		} else {
			sections.remainder = sections.remainder || {
				id: 'remainder',
				title: '',
				articles: [],
			}
			sections.remainder.articles.push(article)
		}
	})
	return Object.keys(sections).map(key => sections[key])
}

export const getSeededColor = seed => {
	const rng = seedrandom(seed)
	const randomIndex = Math.round(rng() * (SOFT_COLORS.length - 1))
	return SOFT_COLORS[randomIndex]
}

const useStyles = makeStyles(
	theme => ({
		root: {
			paddingBottom: theme.spacing(8),
			paddingTop: theme.spacing(8),
		},
		cardContent: {
			minHeight: theme.spacing(19),
		},
		image: {
			bottom: 0,
			left: 0,
			position: 'absolute',
			right: 0,
			top: 0,
		},
		media: {
			//aspectRatio: 16 / 9,
			overflow: 'hidden',
			position: 'relative',

			'&:before': {
				content: '""',
				display: 'block',
				paddingTop: 'calc((9 / 16) * 100%)',
				width: '100%',
			},
		},
		section: {
			marginTop: theme.spacing(6),
		},
	}),
	{ name: 'HelpArticles' }
)

const HelpArticles = () => {
	const classes = useStyles()

	const result = useStaticQuery(graphql`
		query {
			helpPosts: allMdx(
				filter: {
					frontmatter: { published: { ne: false } }
					fileAbsolutePath: { regex: "//content/help//" }
				}
				sort: { fields: frontmatter___date, order: DESC }
			) {
				edges {
					node {
						bannerRemoteImage {
							...bannerImage640
						}
						fields {
							id
							slug
							title
							banner {
								...bannerImage640
							}
							bannerAlt
						}
						excerpt(pruneLength: 120)
					}
				}
			}
		}
	`)

	const articles = result.helpPosts.edges.map(({ node }) => node)
	const sections = splitArticlesIntoSections(articles)

	return (
		<div className={classes.root}>
			<Typography gutterBottom variant={'h2'}>
				Help
			</Typography>

			{sections.map(({ articles, id, title }) => (
				<div className={classes.section} key={id}>
					<Typography gutterBottom variant={'h3'}>
						{title}
					</Typography>

					<Grid container spacing={2}>
						{articles.map(article => {
							const { bannerRemoteImage } = article
							const { banner, bannerAlt } = article.fields
							const image =
								getImage(banner) || getImage(bannerRemoteImage)

							return (
								<Grid
									item
									key={article.fields.id}
									xs={12}
									sm={6}
									md={4}
								>
									<Card raised={false}>
										<CardActionArea
											component={GatsbyLink}
											to={article.fields.slug}
										>
											{image ? (
												<CardMedia
													className={classes.media}
												>
													<div
														className={
															classes.image
														}
													>
														<GatsbyImage
															image={image}
															alt={bannerAlt}
														/>
													</div>
												</CardMedia>
											) : (
												<CardMedia
													className={classes.media}
													style={{
														backgroundColor: getSeededColor(
															article.fields.title
														),
													}}
												>
													<div
														className={
															classes.image
														}
													/>
												</CardMedia>
											)}
											<CardContent
												className={classes.cardContent}
											>
												<Typography
													gutterBottom
													variant={'h5'}
													component={'h2'}
												>
													{article.fields.title}
												</Typography>
												<Typography
													variant={'body2'}
													color={'textSecondary'}
													component={'p'}
												>
													{article.excerpt}
												</Typography>
											</CardContent>
										</CardActionArea>
									</Card>
								</Grid>
							)
						})}
					</Grid>
				</div>
			))}
		</div>
	)
}

export default HelpArticles
