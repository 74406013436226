import React from 'react'
import { Link as GatsbyLink } from 'gatsby'

import MuiLink from '@material-ui/core/Link'

import { transitionProps } from '../utils/transitions'

const Link = React.forwardRef(function Link(props, ref) {
	return (
		<MuiLink
			component={GatsbyLink}
			ref={ref}
			{...transitionProps}
			{...props}
		/>
	)
})

export default Link
