import merge from 'lodash/merge'

import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles'

import defaultThemeConfig from '../../config/theme'

const darkTheme = responsiveFontSizes(
	createMuiTheme(
		merge({}, defaultThemeConfig, {
			palette: {
				background: {
					default: '#303030',
					emphasis: '#555',
					landingPage: '#555',
					footer: '#555',
				},
				type: 'dark',
			},
		})
	)
)

export default darkTheme
