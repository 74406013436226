import merge from 'lodash/merge'

import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles'

import defaultThemeConfig from '../../config/theme'

const lightTheme = responsiveFontSizes(
	createMuiTheme(
		merge({}, defaultThemeConfig, {
			palette: {
				background: {
					default: '#fff',
					emphasis: '#f5f5f5',
					landingPage: '#f5f5f5',
					footer: '#f5f5f5',
				},
			},
		})
	)
)

export default lightTheme
