import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'

import { ThemeProvider } from '@material-ui/core/styles'
import { CssBaseline, useMediaQuery } from '@material-ui/core'

import { darkTheme, lightTheme } from '../../src/themes'

const TopLayout = ({ children }) => {
	const isDark = useMediaQuery('(prefers-color-scheme: dark)')

	const theme = isDark ? darkTheme : lightTheme

	return (
		<>
			<Helmet>
				<meta
					name={'viewport'}
					content={
						'minimum-scale=1, initial-scale=1, width=device-width'
					}
				/>
			</Helmet>
			<ThemeProvider theme={theme}>
				<CssBaseline />
				{children}
			</ThemeProvider>
		</>
	)
}

TopLayout.propTypes = {
	children: PropTypes.node,
}

export default TopLayout
