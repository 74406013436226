import React, { useReducer } from 'react'

export const PricingPlanContext = React.createContext()
export const PricingPlanDispatch = React.createContext()

const initialState = {
	plan: null,
}

const pricingPlanReducer = (state, action) => {
	switch (action.type) {
		case 'setPlan':
			return { ...state, plan: action.payload }

		default:
			return state
	}
}

export const PricingPlanProvider = ({ children }) => {
	const [state, dispatch] = useReducer(pricingPlanReducer, initialState)

	return (
		<PricingPlanContext.Provider value={state}>
			<PricingPlanDispatch.Provider value={dispatch}>
				{children}
			</PricingPlanDispatch.Provider>
		</PricingPlanContext.Provider>
	)
}
