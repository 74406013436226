import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'

const mapPosition = position => {
	switch ( position ) {
		case 'left':
			return 'flex-start'
		case 'right':
			return 'flex-end'
		default:
			return position
	}
}

const styles = theme => ( {
	root: {
		height: '100%',
	},
	paper: {
		borderRadius: 8,
		paddingBottom: theme.spacing(6),
		paddingLeft: theme.spacing(6),
		paddingRight: theme.spacing(6),
		paddingTop: theme.spacing(6),
		width: '100%',
	},
} )

class QuoteBox extends Component {
	static defaultProps = {
		position: 'center',
	}

	static propTypes = {
		author: PropTypes.string,
		classes: PropTypes.object,
		content: PropTypes.string,
		position: PropTypes.oneOf( [ 'center', 'left', 'right' ] ),
	}

	render() {
		const { author, classes, content, position } = this.props
		const justify = mapPosition( position )
		return (
			<Grid container className={classes.root} justify={justify}>
				<Paper className={classes.paper} elevation={4}>
					<Typography align={position} gutterBottom variant="h6">
						{content}
					</Typography>
					<Typography align={position} variant="subtitle1">
						&mdash; {author} &mdash;
					</Typography>
				</Paper>
			</Grid>
		)
	}
}

export default withStyles( styles )( QuoteBox )
