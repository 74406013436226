import React, { PureComponent } from 'react'

import { ThemeProvider, withStyles } from '@material-ui/core/styles'
import {
	Button,
	Container,
	Collapse,
	Grid,
	Hidden,
	Paper,
	Typography,
} from '@material-ui/core/'

import { lightTheme } from '../themes'
import Link from './Link'

const DISMISSED_COOKIE = 'ty.dismissedCookieConsent'

const styles = theme => ({
	root: {
		backgroundColor: theme.palette.background.paper,
		padding: theme.spacing(1, 0),
		position: 'fixed',
		bottom: 0,
		width: '100%',
	},
})

class CookieConsent extends PureComponent {
	state = {
		isCookieDismissed: true,
		isEU: false,
	}

	componentDidMount() {
		const isCookieDismissed =
			localStorage.getItem(DISMISSED_COOKIE) === 'true' || false
		this.setState({ isCookieDismissed })

		fetch(`https://api.ipdata.co?api-key=${process.env.GATSBY_IPDATA_KEY}`)
			.then(data => data.json())
			.then(data => {
				if (data.is_eu) this.setState({ isEU: true })
			})
	}

	dismissCookieConsent = () => {
		localStorage.setItem(DISMISSED_COOKIE, 'true')
		this.setState({ isCookieDismissed: true })
	}

	render() {
		const { classes } = this.props
		const { isCookieDismissed, isEU } = this.state
		const isVisible = !isCookieDismissed && isEU
		return (
			<ThemeProvider theme={lightTheme}>
				<Collapse in={isVisible} mountOnEnter unmountOnExit>
					<Paper className={classes.root} elevation={6} square>
						<Container>
							<Grid
								container
								alignItems={'center'}
								spacing={2}
								justify={'space-between'}
							>
								<Grid item>
									<Hidden only={'xs'}>
										<Typography
											color={'textPrimary'}
											display={'inline'}
											variant={'body2'}
										>
											🍪 Tyto uses cookies. Nom nom nom.
										</Typography>
									</Hidden>
									<Hidden smUp>
										<Typography
											color={'textPrimary'}
											display={'inline'}
											variant={'body2'}
										>
											🍪 Tyto uses cookies.
										</Typography>
									</Hidden>
								</Grid>

								<Grid item>
									<Grid
										container
										alignItems={'center'}
										spacing={2}
									>
										<Grid item>
											<Link
												color={'primary'}
												to={'/legal/privacy'}
												variant={'body2'}
											>
												Privacy Policy
											</Link>
										</Grid>
										<Grid item>
											<Button
												color={'primary'}
												onClick={
													this.dismissCookieConsent
												}
												size={'small'}
												variant={'contained'}
											>
												Cool, cool!
											</Button>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</Container>
					</Paper>
				</Collapse>
			</ThemeProvider>
		)
	}
}

export default withStyles(styles)(CookieConsent)
