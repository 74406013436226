const defaultFontFamily =
	'-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"'

const palette = {
	primary: { main: '#df2a59' },
	secondary: { main: '#2F2D2E' },
	yellow: { main: '#F4E049' },
	green: { main: '#9BC53D' },
	charcoal: { main: '#363537' },
}

const shape = {
	borderRadius: 12,
}

const typography = {
	fontFamily: defaultFontFamily,
	fontSize: 16,
	h1: { fontFamily: defaultFontFamily, fontWeight: 700 },
	h2: { fontFamily: defaultFontFamily, fontWeight: 700 },
	h3: { fontFamily: defaultFontFamily, fontWeight: 700 },
	h4: { fontFamily: defaultFontFamily, fontWeight: 700 },
	h5: { fontFamily: defaultFontFamily, fontWeight: 700 },
	h6: { fontFamily: defaultFontFamily, fontWeight: 700 },
	subtitle1: { fontFamily: defaultFontFamily },
	subtitle2: { fontFamily: defaultFontFamily },
	body1: { fontFamily: defaultFontFamily },
	body2: { fontFamily: defaultFontFamily },
	caption: { lineHeight: 1.7 },
}

const overrides = {
	MuiButton: {
		root: {
			borderRadius: shape.borderRadius * 10,
			fontWeight: 700,
			padding: '8px 24px',
			textTransform: 'capitalize',
		},
		containedPrimary: {
			border: '2px solid transparent',

			'&:hover': {
				backgroundColor: 'transparent',
				border: `2px solid ${palette.primary.main}`,
				color: palette.primary.main,
			},
		},
		containedSizeLarge: {
			padding: '12px 32px',
		},
	},
}

module.exports = {
	typography,
	palette,
	overrides,
	props: {
		MuiButton: {
			disableElevation: true,
		},
		MuiContainer: {
			maxWidth: 'md',
		},
	},
	shape,
}
